import React, { Children, cloneElement, useContext, useEffect } from 'react'
import { TabsContext, TabsContextType } from './Section'
import { Props as TabProps } from './Tab'
import { styled } from 'styles/stitches.config'

const Ol = styled('ol', {})

type Props = {
  children: React.ReactNode
}

export const Header = ({ children, ...props }: Props) => {
  const { activeTab, setActiveTab } = useContext<TabsContextType>(TabsContext)

  useEffect(() => {
    // activeTab i a string; find the matching tabIndex, else use tabIndex:0
    if (typeof activeTab === 'string') {
      const tabIndex = Children.toArray(children).findIndex((child) => {
        if (React.isValidElement<TabProps>(child)) {
          return child.props.tabName === activeTab
        }

        return false
      })

      setActiveTab(tabIndex >= 0 ? tabIndex : 0)
    }

    // activeTab is invalid; default to tabIndex:0
    if (typeof activeTab === 'number' && activeTab > Children.toArray(children).length - 1) {
      return setActiveTab(0)
    }
  }, [activeTab, children, setActiveTab])

  return (
    <Ol {...props}>
      {Children.toArray(children).map((child, i) => {
        if (React.isValidElement<TabProps>(child)) {
          return cloneElement(child, {
            active: (activeTab === i || activeTab === child.props.tabName),
            tabIndex: i,
          })
        }

        return null
      })}
    </Ol>
  )
}
