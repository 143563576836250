import React, { useContext } from 'react'
import { TabsContextType, TabsContext } from './Section'
import { styled } from 'styles/stitches.config'

const Div = styled('div', {})

type Props = {
  children: React.ReactNode
  forTab?: string
  tabIndex?: number
}

export const View = ({ children, forTab, tabIndex, ...props }: Props) => {
  const { activeTab } = useContext<TabsContextType>(TabsContext)

  if (activeTab === forTab || activeTab === tabIndex) {
    return <Div {...props}>{children}</Div>
  }

  return null
}
