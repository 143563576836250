import { styled } from 'styles/stitches.config'
import * as Tabs from 'components/TabsKit'

export const Section = styled(Tabs.Section, {
  width: '100%',
})

export const Header = styled(Tabs.Header, {
  fontSize: 0,
  width: '100%',
})

export const Tab = styled(Tabs.Tab, {
  backgroundColor: 'transparent',
  border: '2px solid $black',
  borderRadius: 16,
  color: '$black',
  display: 'inline-block',
  fontSize: '$body2',
  fontWeight: 'bold',
  lineHeight: '$body2',
  margin: '$xxs',
  padding: '$xxs $s',

  variants: {
    active: {
      true: {
        backgroundColor: '$black',
        color: '$white',
      },
    },
  },
})

export const Main = styled(Tabs.Main, {
  width: '100%',
})

export const View = styled(Tabs.View, {
})
