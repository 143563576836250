import React, { useContext } from 'react'
import { TabsContext, TabsContextType } from './Section'
import { styled } from 'styles/stitches.config'

const Li = styled('li', {
  variants: {
    active: {
      true: {},
    },
  },
})

export type Props = {
  active?: boolean
  children?: React.ReactNode
  label?: string
  tabIndex?: number
  tabName?: string
  onClickTab?: (tab: number | string) => void
}

export const Tab = ({ active, children, label, tabIndex, tabName, onClickTab, ...props }: Props) => {
  const { setActiveTab } = useContext<TabsContextType>(TabsContext)

  const handleClick = () => {
    if (tabName) {
      setActiveTab(tabName)

      if (onClickTab) {
        onClickTab(tabName)
      }
    } else if (tabIndex !== undefined) {
      setActiveTab(tabIndex)

      if (onClickTab) {
        onClickTab(tabIndex)
      }
    }
  }

  if ((!children || (Array.isArray(children))) && !label) {
    return null
  }

  return (
    <Li
      active={!!active}
      onClick={handleClick}
      {...props}
    >
      {label || children}
    </Li>
  )
}
