import { Span } from 'design-system'

export const minutesToNewDate: (minutes: number) => Date = (minutes: number) => {
  const hr = Math.floor(minutes / 60)
  const min = minutes % 60
  const newDate = new Date()

  newDate.setHours(hr, min, 0, 0)
  return newDate
}

export const minutesToLocalTime = (minutes: number) => {
  const newDate = minutesToNewDate(minutes)

  const timeString = newDate.toLocaleTimeString([], {
    hour: 'numeric', minute: '2-digit', hour12: true,
  })

  return timeString.toLowerCase().replace(' ', '')
}

export const minutesToMicrotime = (minutes: number) => {
  const newDate = minutesToNewDate(minutes)
  return newDate.getTime()
}

type Props = {
  close: number
  open: number
}

const OperatingHours = ({ close, open }: Props) => {
  if (open === 0 && close === 1439) {
    return (
      <Span>Open 24 hours</Span>
    )
  }

  if (open === close) {
    return (
      <Span>Closed</Span>
    )
  }

  return (
    <Span>{`${minutesToLocalTime(open)} – ${minutesToLocalTime(close)}`}</Span>
  )
}

export default OperatingHours
