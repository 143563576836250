import React, { Children, cloneElement } from 'react'
import { Props as TabProps } from './Tab'
import { styled } from 'styles/stitches.config'

const Div = styled('div', {})

type Props = {
  children: React.ReactNode
}

export const Main = ({ children, ...props }: Props) => (
  <Div {...props}>
    {Children.toArray(children).map((child, i) => {
      if (React.isValidElement<TabProps>(child)) {
        return cloneElement(child, {
          tabIndex: i,
        })
      }

      return null
    })}
  </Div>
)
