import React, { useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'

import * as Icon from 'components/DesignSystemIcons'
import Img from 'components/ImageFadeIn'
import { MastheadGroup, MastheadIcon, MastheadTitle } from 'components/Masthead'
import Menu from 'components/pages/explore/stores/StoreMenu'
import OperatingHours from 'components/OperatingHours'
import { StandardPage } from 'components/Layout'
import * as Widget from 'components/Widget'

import { styled } from 'styles/stitches.config'
import { H2 } from 'design-system'

import { browserBack } from 'utils/browserBack'
import { isEmpty } from 'lodash'
import { storeItemOptionsState, storeItemRemovedOptionsState } from 'config/states'
import { useResetRecoilState } from 'recoil'

import type { StoreMenuResponse } from 'types/api'
import { StoreDetailsOnMap } from '../../../../types/api/store'
import FavoriteButton from 'components/FavoriteButton'
import { logError } from 'utils/honeybadger'

const StoreTags = dynamic(() => import('components/pages/explore/stores/StoreTags'), {
  ssr: false
})

const StoreName = styled(H2, {
  color: '$grey1000',
  fontWeight: '$black',
  margin: '0 $s',
})

const StoreInfo = styled('ul', {
  color: '$grey800',
  lineHeight: '$heading4',
  listStyle: 'none',

  '& > li': {
    margin: 0,
    padding: 0,
  }
})

const ImgWrapper = styled('div', {
  position: 'relative'
})

const FavoriteButtonWrapper = styled('div', {
  position: 'absolute',
  top: '$s',
  right: '$s'
})

const StoreId = (storeDetails: StoreMenuResponse) => {
  const { close, menu, online, open, photo, storeAddress, storeId, storeName, wait, featuredItemIds, companyId } = storeDetails
  const store = { close, online, open, wait }
  const resetOptions = useResetRecoilState(storeItemOptionsState)
  const resetRemovedOptions = useResetRecoilState(storeItemRemovedOptionsState)

  useEffect(() => {
    return () => {
      resetOptions()
      resetRemovedOptions()
    }
  }, [resetOptions, resetRemovedOptions])

  const router = useRouter()

  const handleInfoOnClick = () => {
    router.push({
      pathname: '/explore/stores/[storeId]/info',
      query: {
        storeId,
      },
    }).catch(logError)
  }

  const handleClickPurchaseGiftCard = () => {
    router.push({
      pathname: '/gift-cards/purchase/[companyId]',
      query: {
        companyId,
        storeId,
      },
    }).catch(logError)
  }

  const HeaderComponent = useCallback(() => (
    <MastheadGroup>
      <MastheadIcon aria-label="back-button" onClick={browserBack} type="button">
        <Icon.ChevronLeft />
      </MastheadIcon>
      <MastheadTitle center small>{storeName} - Menu</MastheadTitle>
      <MastheadIcon as="span">
        <Icon.Blank />
      </MastheadIcon>
    </MastheadGroup>
  ), [storeName])

  // istanbul ignore next
  return (
    <StandardPage
      headerComponent={HeaderComponent}
      headerVariant="standard"
    >
      <ImgWrapper>
        <Img aspectRatio={40} alt={storeName} src={photo ?? '/images/coffee-shop-default.jpg'} />
        <FavoriteButtonWrapper>
          <FavoriteButton storeId={storeDetails.storeId} like={storeDetails.favorite} />
        </FavoriteButtonWrapper>
      </ImgWrapper>
      <StoreTags store={store as StoreDetailsOnMap} />
      <StoreName data-testid="storeId-page">{storeName}</StoreName>

      <Widget.Composed
        banner
        chevron
        css={{
          [`& ${Widget.Main}`]: {
            backgroundColor: 'transparent',
          },

          [`& ${Widget.Border}`]: {
            paddingLeft: 0,
          },

          [`& ${Widget.Chevron}`]: {
            paddingRight: '$s'
          }
        }}
        onClick={handleInfoOnClick}
      >
        <StoreInfo aria-label="store-info" >
          <li data-testid="store-address">
            <Icon.Inliner>
              <Icon.MapMarker size={16} />
            </Icon.Inliner> {storeAddress}
          </li>
          <li data-testid="operating-hours">
            <Icon.Inliner css={{ margin: 1 }}>
              <Icon.Clock size={14} />
            </Icon.Inliner> <OperatingHours close={close} open={open} />
          </li>
        </StoreInfo>
      </Widget.Composed>

      <Widget.Composed
        banner
        chevron
        blueChevron
        css={{
          alignItems: 'center',
          color: '$blue900',
          cursor: 'pointer',

          [`& ${Widget.Main}`]: {
            backgroundColor: 'transparent',
            margin: '0 $s',
            padding: 0,
          },

          [`& ${Widget.Border}`]: {
            backgroundColor: '$blue100',
            border: 'none',
            margin: 0,
            padding: '$xs $s',
          },

          [`& ${Widget.Children}`]: {
            alignItems: 'center',
            fontSize: '$body2',
            lineHeight: '$body2',
            fontWeight: '$bold',
          }
        }}
        onClick={handleClickPurchaseGiftCard}
        data-testid="gift-card-purchase"
      >
        <Icon.Inliner css={{
          marginRight: '$s'
        }}>
          <Icon.GiftCard />
        </Icon.Inliner>
        <span>Purchase Loyalty Card</span>
      </Widget.Composed>

      {!isEmpty(menu) && (
        <Menu featuredItemIds={featuredItemIds} data={menu} storeId={storeId} popularItems={storeDetails.popularItems} />
      )}
    </StandardPage>
  )
}

export default StoreId
