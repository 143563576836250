import { useCallback, useMemo } from 'react'

import * as MenuTabs from 'components/pages/explore/stores/MenuTabs'
import * as Widget from 'components/Widget'

import StoreMenuItem from 'components/pages/explore/stores/StoreMenu/StoreMenuItem'

import { styled } from 'styles/stitches.config'
import { H2, H3 } from 'design-system/headings'

import type { StoreCategory, StoreMenu, StoreMenuItem as MenuItem } from 'types/api'
import { uniqueId, each, union, filter, includes, isEmpty } from 'lodash'
import { useRouter } from 'next/router'

const Category = styled('div', {
  borderBottom: '1px solid $grey200',
  color: '$grey1000',
  display: 'block',
  marginBottom: '$v',
  width: '100%',
})

const CategoryName = styled(H2, {
  margin: '$xxl $s $xs',
})

const SubCategory = styled('div', {
  margin: '$xxl 0 $xs',
})

const SubcategoryName = styled(H3, {
  backgroundColor: '$grey200',
  borderRadius: 15,
  color: '$grey1000',
  marginBottom: '$xxs',
  padding: '0 $s',
})

const Menu = ({ data, storeId, featuredItemIds, popularItems }: { data: StoreMenu, storeId: string, featuredItemIds: string[], popularItems: string[] }) => {
  const parseNameToID = useCallback((name: string) => name.toLowerCase().replace(' ', '-'), [])
  const router = useRouter()
  const tab = router.query.tab as string | undefined

  const defaultTab = useMemo(() => !!tab ? parseInt(tab) : undefined, [tab])

  const onClickTab = useCallback((tab: string | number) => {
    router.replace({
      pathname: '/explore/stores/[storeId]',
      query: {
        storeId,
        tab
      }
    }, undefined, {
      shallow: true
    })
  }, [router, storeId])

  const getFeaturedItemsByIds = (category: StoreCategory) => {
    let itemsWithinCategory: MenuItem[] = []

    each(category.subCategories, (subCat) => itemsWithinCategory = union(subCat.items, itemsWithinCategory))

    const data = filter(itemsWithinCategory, (item) => includes(featuredItemIds, item.itemId))

    return data
  }

  const categories = useMemo(() => {
    return data.map((category) => ({
      ...category,
      subCategories: category.subCategories.map((subCategory) => ({
        ...subCategory,
        items: subCategory.items.map((item) => {
          let status

          if (item.latest) {
            status = 'new'
          }

          if (popularItems.includes(item.itemId)) {
            status = 'popular'
          }

          return {
            ...item,
            status
          }
        })
      }))
    }))
  }, [data, popularItems])

  const menuTabsElements = useMemo(() => data.map((category) => (
    <MenuTabs.Tab key={`${category.name} Tab`} onClickTab={onClickTab}>
      {category.name}
    </MenuTabs.Tab>
  )), [data, onClickTab])

  return (
    <MenuTabs.Section
      css={{ width: '100%' }}
      defaultTab={defaultTab}
    >
      <Widget.Composed
        css={{
          margin: 0,

          [`& ${Widget.Main}`]: {
            backgroundColor: 'transparent',
            padding: '0 $xxs',
          },

          [`& ${Widget.Border}`]: {
            paddingLeft: 0,
          },
        }}
        unstyled
      >
        <MenuTabs.Header>
          {menuTabsElements}
        </MenuTabs.Header>

        <MenuTabs.Main>
          {categories.map((category) => (
            <MenuTabs.View
              key={`${category.name} Menu`}
            >
              <Category>
                <CategoryName id={parseNameToID(category.name)}>{category.name}</CategoryName>
              </Category>

              {!isEmpty(getFeaturedItemsByIds(category)) && (
                <SubCategory>
                  <SubcategoryName>Featured Items</SubcategoryName>
                  {getFeaturedItemsByIds(category).map((item) => (
                    <StoreMenuItem key={item.itemId} item={item} storeId={storeId} />
                  ))}
                </SubCategory>
              )}

              {category.subCategories.map((subcat) => (
                <SubCategory key={uniqueId(subcat.name)}>
                  <SubcategoryName>{subcat.name}</SubcategoryName>
                  {subcat.items.map((item) => (
                    <StoreMenuItem key={item.itemId} item={item} storeId={storeId} />
                  ))}
                </SubCategory>
              ))}
            </MenuTabs.View>
          ))}
        </MenuTabs.Main>
      </Widget.Composed>
    </MenuTabs.Section>
  )
}

export default Menu
