import type { GetServerSidePropsContext, NextPage } from 'next'
import { validate } from 'uuid'

import Screen from 'screens/explore/stores/storeId'

import { api } from 'config/apiClient'

import { StoreMenuResponse } from 'types/api'
import { StoreIdMapping } from 'utils/store'

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const cookie = context.req.headers.cookie

  if (StoreIdMapping[context.params?.storeId as string]) {
    return {
      redirect: {
        destination: `/explore/stores/${StoreIdMapping[context.params?.storeId as string]}`,
      }
    }
  }

  const isUuid = validate(context.params?.storeId as string)

  if (!isUuid) {
    return {
      redirect: {
        destination: '/explore/stores'
      }
    }
  }

  const { data } = await api('getStoreDetailsById', {
    storeId: context.params?.storeId as string,
  }, cookie)

  return {
    props: data
  }
}

const StoreId: NextPage<StoreMenuResponse> = (query) => (
  <Screen {...query} />
)

export default StoreId
