import React, { createContext, useState } from 'react'
import { styled } from 'styles/stitches.config'

export type TabsContextType = {
  activeTab: number | string
  setActiveTab: (value: number | string) => void
}

export const TabsContext = createContext<TabsContextType>({
  activeTab: 0,
  setActiveTab: () => null,
})

const Div = styled('div', {})

type Props = {
  children: React.ReactNode
  defaultTab?: number | string
}

export const Section = ({ children, defaultTab = 0, ...props }: Props) => {
  const [activeTab, setActiveTab] = useState(defaultTab)

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <Div {...props}>
        {children}
      </Div>
    </TabsContext.Provider>
  )
}
